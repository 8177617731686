import React from 'react';
import { t } from 'ttag';
import ErrorPage from './ErrorPage';
import withLoading from '../../core/createLoadingAction';

function action() {
  return {
    title:     t`Kritinė klaida`,
    component: <ErrorPage />,
  };
}

export default withLoading(action);
