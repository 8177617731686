/* eslint-disable global-require */
import paths from '../constants/paths';

// The top-level (parent) route
const routes = {
    path: '',

    // Keep in mind, routes are evaluated in order
    children: [
        {
            path: '',
            load: () => import(/* webpackChunkName: 'homeRoute' */ './home/homeRoute'),
        },
        {
            path: paths.about,
            load: () => import(/* webpackChunkName: 'about' */ './about'),
        },
        {
            path: paths.login,
            load: () => import(/* webpackChunkName: 'loginRoute' */ './login/loginRoute'),
        },
        {
            path: paths.users,
            load: () => import(/* webpackChunkName: 'usersRoute' */ './admin/usersRoute'),
        },
        {
            path: paths.addUser,
            load: () => import(/* webpackChunkName: 'addUserRoute' */ './admin/addUserRoute'),
        },
        {
            path: paths.viewUser,
            load: () => import(/* webpackChunkName: 'viewUserRoute' */ './admin/viewUserRoute'),
        },
        {
            load: () => import(/* webpackChunkName: 'updateUserRoute' */ './admin/updateUserRoute'),
            path: paths.updateUser,
        },
        {
            path: paths.exam,
            load: () => import(/* webpackChunkName: 'examRoute' */ './exam/examRoute'),
        },
        {
            path: paths.startExam,
            load: () => import(/* webpackChunkName: 'examStartRoute' */ './exam/examStartRoute'),
        },
        {
            path: paths.examFinish,
            load: () => import(/* webpackChunkName: 'examFinishRoute' */ './exam/examFinishRoute'),
        },
        {
            path: paths.evaluateExam,
            load: () => import(/* webpackChunkName: 'evaluateExamRoute' */ './admin/evaluateExamRoute'),
        },
        {
            path: paths.examQuestion,
            load: () => import(/* webpackChunkName: 'examQuestionRoute' */ './exam/examQuestionRoute'),
        },
        {
            path: paths.examActiveQuestion,
            load: () => import(/* webpackChunkName: 'examActiveQuestionRoute' */ './exam/examActiveQuestionRoute'),
        },
        {
            path: paths.examControl,
            load: () => import(/* webpackChunkName: 'examControlRoute' */ './exam/examControlRoute'),
        },
        {
            path: paths.examTypes,
            load: () => import(/* webpackChunkName: 'examTypesRoute' */ './admin/examTypesRoute'),
        },
        {
            path: paths.examType,
            load: () => import(/* webpackChunkName: 'examTypeRoute' */ './admin/examTypeRoute'),
        },
        {
            path: paths.category,
            load: () => import(/* webpackChunkName: 'categoryViewRoute' */ './admin/categoryViewRoute'),
        },
        {
            path: paths.editCategory,
            load: () => import(/* webpackChunkName: 'categoryEditRoute' */ './admin/categoryEditRoute'),
        },
        {
            path: paths.addQuestion,
            load: () => import(/* webpackChunkName: 'addQuestionRoute' */ './admin/addQuestionRoute'),
        },
        {
            path: paths.editQuestion,
            load: () => import(/* webpackChunkName: 'editQuestionRoute' */ './admin/editQuestionRoute'),
        },
        {
            path: paths.importQuestions,
            load: () => import(/* webpackChunkName: 'importQuestionsRoute' */ './admin/importQuestionsRoute'),
        },

        // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
        {
            path: '(.*)',
            load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
        },
    ],

    async action({ next }) {
        // Execute each child route until one of them return the result
        const route = await next();

        // Provide default values for title, description etc.
        route.title = `${route.title || 'Untitled Page'}`;
        route.description = route.description || '';

        return route;
    },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
    routes.children.unshift({
        path:   '/error',
        action: require('./error').default,
    });
}

export default routes;
