import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import user from './user';
import runtime from './runtime';

export default combineReducers({
  user,
  runtime,
  form,
});
