import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'ttag';

class ErrorPage extends React.Component {
  static propTypes = {
    error: PropTypes.shape({
      name:    PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      stack:   PropTypes.string.isRequired,
    }),
  };

  static defaultProps = {
    error: null,
  };

  render() {
    if (__DEV__ && this.props.error) {
      return (
        <div>
          <h1>{this.props.error.name}</h1>
          <pre>{this.props.error.stack}</pre>
        </div>
      );
    }

    return (
      <div>
        <h1>{t`Klaida`}</h1>
        <p>{t`Ups, įvyko kritinė klaida.`}</p>
      </div>
    );
  }
}

export { ErrorPage as ErrorPageWithoutStyle };
export default ErrorPage;
