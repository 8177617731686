export default {
    home:               '/',
    about:              '/about',
    login:              '/login',
    logout:             '/logout',
    users:              '/users',
    addUser:            '/users/add',
    viewUser:           '/users/:id',
    updateUser:         '/users/:id/update',
    manage:             '/manage',
    exam:               '/exam/:id',
    startExam:          '/exam/:id/start',
    examFinish:         '/exam/:id/finish',
    examQuestion:       '/exam/:id/question/:no',
    examActiveQuestion: '/exam/:id/active',
    examControl:        '/exam/:id/control/:no',
    evaluateExam:       '/exam/:id/evaluate',
    examTypes:          '/manage',
    examType:           '/manage/:id',
    category:           '/categories/:id',
    questionInCategory: '/categories/:id#:question',
    editCategory:       '/categories/:id/edit',
    addQuestion:        '/categories/:id/add-question',
    editQuestion:       '/questions/:id',
    importQuestions:    '/import-questions',
};
