/* eslint-disable import/prefer-default-export */

import { SET_RUNTIME_VARIABLE, SET_LOADING } from '../constants';

export function setRuntimeVariable({ name, value }) {
  return {
    type:    SET_RUNTIME_VARIABLE,
    payload: {
      name,
      value,
    },
  };
}

export const setLoading = (loading = true) => ({ type: SET_LOADING, payload: { loading } });
