import { setLoading } from '../actions/runtime';

export default actionFunc => async (...args) => {
  const { store } = args[0];
  store.dispatch(setLoading(true));
  const result = await actionFunc(...args);

  store.dispatch(setLoading(false));
  return result;
};
